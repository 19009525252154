import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "fill-height": "" } },
    [
      _c(
        VLayout,
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "", sm8: "", md4: "" } },
            [
              _c(VImg, {
                staticClass: "mb-2",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  alt: "logo-recuperi"
                }
              }),
              _c(
                VCard,
                { staticClass: "elevation-12" },
                [
                  _c(
                    VToolbar,
                    { attrs: { dark: "", color: "primary" } },
                    [_c(VToolbarTitle, [_vm._v("Nova senha")])],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          ref: "form",
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.validarSubmit.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(VTextField, {
                            ref: "password",
                            attrs: {
                              "prepend-icon": "lock",
                              name: "nova senha",
                              label: "Nova senha",
                              type: _vm.show1 ? "text" : "password",
                              "append-icon": _vm.show1
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              hint:
                                "Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)",
                              "data-cy": "signinPasswordField",
                              rules: _vm.passwordRules,
                              required: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show1 = !_vm.show1
                              },
                              input: function($event) {
                                return _vm.$refs.passwordConfirmation.validate()
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "tab",
                                    9,
                                    $event.key,
                                    "Tab"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.$refs.passwordConfirmation.focus()
                              }
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c(VTextField, {
                            ref: "passwordConfirmation",
                            attrs: {
                              "prepend-icon": "lock",
                              name: "Repita a nova senha",
                              label: "Repita a nova senha",
                              type: _vm.show1 ? "text" : "password",
                              "append-icon": _vm.show1
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              "data-cy": "signinPasswordField",
                              rules: _vm.passwordRulesConfirmation,
                              required: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show1 = !_vm.show1
                              }
                            },
                            model: {
                              value: _vm.passwordConfirmation,
                              callback: function($$v) {
                                _vm.passwordConfirmation = $$v
                              },
                              expression: "passwordConfirmation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.valid,
                            "data-cy": "signinSubmitBtn",
                            loading: _vm.load_troca_senha
                          },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(" Registrar nova senha "),
                          _c(VIcon, { staticClass: "ml-1" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: "red white--text",
              mensagemSnackbar: _vm.snackbarText
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.dialogMensagemFinal,
            callback: function($$v) {
              _vm.dialogMensagemFinal = $$v
            },
            expression: "dialogMensagemFinal"
          }
        },
        [
          _c(
            VCard,
            [
              _c(VCardText, { staticClass: "pt-5 mb-n10" }, [
                _vm._v(_vm._s(_vm.resposta_requisicao))
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "", to: "/login" },
                      on: {
                        click: function($event) {
                          _vm.dialogMensagemFinal = !_vm.dialogMensagemFinal
                        }
                      }
                    },
                    [_vm._v("ok")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }